import moment from 'moment';

const initialState = {
    shopifyData: null,
};

export function MyReducer(state = initialState, action) {
    // console.log('MyReducer Payload: ' + JSON.stringify(action.payload));
    switch (action.type) {
        case 'setShopifyData':
            return {
                ...state,
                shopifyData: action.payload
            };
        default:
            return state;
    }
}