import React, { useState } from 'react';
import Sticky from 'react-stickynode';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

const Navbar = (props) => {
    const navigate = useNavigate();

    var { mClass, mContainer, mainlogo, stickylogo, noButtons } = props;
    const [toggle, setToggle] = useState(false)

    return (
        <div>
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <nav className={`navbar navbar-expand-lg navbar-light ${mClass} navbar-color nav-height`} style={{ borderBottom: '0.5px solid #04B486' }}>
                    <div className={`container my-nav nav-height ${mContainer}`}>

                        <a className="navbar-brand logo_h logo-margin-left" href="/" style={{ marginTop: 10 }}>
                            <img style={{ maxWidth: 180, maxHeight: 70 }} src={require("../../image/" + mainlogo)} alt="" />
                            <img style={{ maxWidth: 180, maxHeight: 70 }} src={require("../../image/" + stickylogo)} alt="" />
                        </a>

                        <div
                            onClick={() => {
                                setToggle(!toggle)
                            }}
                            className="navbar-toggler" style={{ outline: 'none', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                            <img
                                className='toggle-icon'
                                style={{ width: toggle ? 40 : 40, height: toggle ? 40 : 40, outline: 'none', outlineColor: 'transparent', outlineWidth: 0 }}
                                src={toggle ? require("../../image/cross_icon.png") : require("../../image/menu_icon.png")} />
                        </div>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="nav navbar-nav" style={{ alignItems: 'center', marginTop: 8 }}>

                                {/* <a className="nav-item" href='#benefits'>
                                        <div className="nav-link" >
                                            Benefits
                                        </div>
                                    </a> */}
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="benefits" spy={true} smooth={false} offset={-66} duration={500}>
                                        Benefits
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="features" spy={true} smooth={false} offset={-66} duration={500}>
                                        Features
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="pricing" spy={true} smooth={false} offset={-66} duration={500}>
                                        Pricing
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="showcase" spy={true} smooth={false} offset={-66} duration={500}>
                                        Showcase
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" activeClass="active" to="FAQ" spy={true} smooth={false} offset={-66} duration={1000}>
                                        FAQs
                                    </Link>
                                </li>
                                <a
                                    className="nav-item"
                                    onClick={() => {
                                        navigate(
                                            '/shopify-step1',
                                            {
                                                state: {
                                                    url: '',
                                                    platform: 1,
                                                }
                                            }
                                        );
                                    }}>
                                    <div
                                        className='btn my-button my-btn-bg' style={{ fontWeight: 'bold', fontFamily: 'sans-serif', backgroundColor: '#04B486', border: '0px solid yellow', color: '#fff', paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, fontSize: 14 }}>
                                        Order Your App
                                    </div>
                                </a>
                            </ul>
                        </div>

                        {!toggle ?
                            null
                            :
                            <ul className="nav navbar-nav animate-down" style={{ marginTop: 0, display: 'flex', width: '100%', border: '1px solid lightgray', backgroundColor: 'white' }}>
                                <li className="nav-item">
                                    <Link
                                        onClick={() => {
                                            setToggle(false)
                                        }}
                                        className="nav-link" activeClass="active" to="free-demo" spy={true} smooth={false} offset={-66} duration={500}>
                                        Get Demo Now
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        onClick={() => {
                                            setToggle(false)
                                        }}
                                        className="nav-link" activeClass="active" to="pricing" spy={true} smooth={false} offset={-66} duration={500}>
                                        Benefits
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        onClick={() => {
                                            setToggle(false)
                                        }}
                                        className="nav-link" activeClass="active" to="features" spy={true} smooth={false} offset={-66} duration={500}>
                                        Features
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        onClick={() => {
                                            setToggle(false)
                                        }}
                                        className="nav-link" activeClass="active" to="pricing" spy={true} smooth={false} offset={-66} duration={500}>
                                        Pricing
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        onClick={() => {
                                            setToggle(false)
                                        }}
                                        className="nav-link" activeClass="active" to="showcase" spy={true} smooth={false} offset={-66} duration={500}>
                                        Showcase
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        onClick={() => {
                                            setToggle(false)
                                        }}
                                        className="nav-link" activeClass="active" to="FAQ" spy={true} smooth={false} offset={-66} duration={1000}>
                                        FAQs
                                    </Link>
                                </li>
                            </ul>
                        }

                    </div>
                </nav>

            </Sticky>
        </div>
    )
}

export default Navbar;