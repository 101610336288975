import React, { useState } from 'react';
import '../App.css';
import { toast } from 'react-toastify';

const showToast = (type, msg) => {
    let config = {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    };

    if(type === 'success'){
        toast.success(msg, config);
    }
    else if(type === 'error'){
        toast.error(msg, config);
    }
    else if(type === 'info'){
        toast.info(msg, config);
    }
    else if(type === 'warning'){
        toast.warning(msg, config);
    }
};

export default showToast;

