import React, { useState, useEffect } from 'react';
import FooterTwo from '../Footer/Footer';
import Navbar1 from './../Navbar/Navbar1';
import socialData from '../socialData';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import Api from '../Api/api'

const Success = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch()
    // const [shopifyData, setShopifyData] = useState(null)
    // let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    useEffect(() => {
        let shopifyData = localStorage.getItem('shopifyData')
        let orderID = localStorage.getItem('orderID')
        if (shopifyData != null) {
            shopifyData = JSON.parse(shopifyData)
            console.log('shopifyData: ' + JSON.stringify(shopifyData));
            Api.updateOrderPaid(orderID)
            sendEmailToCustomer(shopifyData.email, shopifyData.platform)
            sendEmailToAdmin()
            localStorage.removeItem('shopifyData')
            localStorage.removeItem('orderID')
        }
        else {
            console.log('null1');
        }
    }, [])

    const sendEmailToCustomer = (email, platform) => {
        console.log('email: ' + email);
        console.log('platform: ' + platform);

        let mailParams = {
            "accessToken": '03X4H03hf8mNX3vmdhiLI',
            "service_id": 'service_vkaxqvg',
            "template_id": 'template_lv3syqu',
            "user_id": 'Hrb9PrGzCxo7wOfqg',
            "template_params": {
                "email": email,
                "message": 'Congratulations! Your order for ' + platform + ' is confirmed. Within 24 hours you will get a testable build you can install on your device and test. Please stay tuned we will be working together 😊',
            }
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mailParams)
        })
            .then(response => response.text())
            .then(result => {
                console.log('email sent: ' + result.text);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const sendEmailToAdmin = () => {
        let mailParams = {
            "accessToken": '03X4H03hf8mNX3vmdhiLI',
            "service_id": 'service_vkaxqvg',
            "template_id": 'template_mwyz8jd',
            "user_id": 'Hrb9PrGzCxo7wOfqg',
            "template_params": {
                "from_name": "ShopifyToApp",
                "email": "",
                "message": 'You got a new Shopify App order!',
            }
        }
        fetch('https://api.emailjs.com/api/v1.0/email/send', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mailParams)
        })
            .then(response => response.text())
            .then(result => {
                console.log('email sent: ' + result.text);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const getPlatformName = (platform) => {
        if (platform == 1)
            return 'Android'
        else if (platform == 2)
            return 'iOS'
        else if (platform == 3)
            return 'Android & iOS'
    }

    // if(shopifyData == null){
    //     return null
    // }

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>
            <Navbar1 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />

            <div className='shopify-step-parent' style={{ marginTop: 110, marginBottom: 50, display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center' }}>

                <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', padding: 40, border: '1px solid lightgray', borderRadius: 10 }}>
                    <div style={{ fontSize: 32, color: '#04B486', textAlign: 'center' }}>
                        Congratulations!
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        Your order is confirmed! Within 24 hours our developer will reach you out sharing app demo. You can check and ask for any customizations. Please stay tuned :)
                    </div>
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        <span style={{ fontWeight: 'bold' }}>You can contact us anytime for update:</span><br />
                        <div style={{ color: 'gray' }}><span style={{ color: 'gray' }}>Email</span>: saif052m@gmail.com</div>
                        {/* <b style={{color:'#04B486'}}>Whatsapp: +923074052841</b><br /> */}
                        <button
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send?text=Hi! I just requested my app demo. Please let me know once ready!&phone=+923074052841", '_blank')
                            }}
                            style={{ alignSelf: 'center', width: '100%', marginTop: 10, textAlign: 'center', fontSize: 22, border: '0px', backgroundColor: 'transparent', fontWeight: 'bold', color: '#04B486', borderRadius: 5 }}>
                            Send hi on whatsapp!
                        </button>
                    </div>
                </div>

            </div>
            <FooterTwo noHeight socialData={socialData} />
        </div>
    )
}

export default Success;