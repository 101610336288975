import React, { useState, useEffect } from 'react';
import { Reveal } from 'react-reveal/';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import PortfolioSlider from '../Portfolio/PortfolioSlider';
import Testimonials from '../Testimonial/Testimonials';
import FAQ from '../FAQ/FAQ';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import videos from '../../assets/video/shopify-demo1.mp4'
import bag from '../../image/bag.svg'
import upArrow from '../../image/up-arrow.svg'
import repeat from '../../image/repeat.svg'
import boost from '../../image/boost.svg'
import alert from '../../image/alert.svg'
import Plans from '../Shopify/Plans';
import Blink from 'react-blink-text';
import Api from '../Api/api'
import db from '../../firebase/firebase';

// ff5959

const darkTxt = '#000'
const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const Body = () => {
    const navigate = useNavigate();

    const [url, setUrl] = useState('')

    useEffect(() => {
        saveClick()
    }, [])

    let click = false 
    const saveClick = async () => {
        if (click) {
            return
        }
        click = true
        const link = window.location.href
        if (link.includes('?')) {
            const [name, id] = link.split('?')[1]?.split('=')
            console.log('key: ' + name);
            console.log('code: ' + id);
            localStorage.setItem('ref-code', id)
            let user = await db.collection('affiliate-users')
                .where('code', '==', id+'')
                .get();
            if (user.docs.length > 0) {
                console.log('A');
                let userID = user.docs[0].id
                let clicks = user.docs[0].data().clicks
                clicks.push({ timestamp: new Date() })
                console.log('clicks: '+JSON.stringify(clicks));
                db.collection('affiliate-users')
                    .doc(userID)
                    .set({
                        clicks: clicks
                    }, { merge: true })
            }
            else{
                console.log('B');
            }
        }
    }

    const showStep = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent' style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 10 }}>
                <div className='step' style={{ fontFamily: 'sans-serif', backgroundColor: '#04B486', color: 'white' }}>
                    <div>{step}</div>
                </div>
                <div className='step-txt' style={{ fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: 22, color: 'white' }}>
                    {stepTxt}
                </div>
                <div className='step-txt' style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 16, color: 'white', lineHeight: 1.5 }}>
                    {stepDesc}
                </div>
            </div>
        )
    };

    const showBenefit = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent' style={{ flex: 1, marginLeft: 10, marginRight: 10, marginTop: 15 }}>
                <img
                    style={{ width: 90, height: 90, alignSelf: 'center' }}
                    src={
                        step == 2 ?
                            require('../../image/a1.png')
                            :
                            step == 3 ?
                                require('../../image/a5.png')
                                :
                                step == 4 ?
                                    require('../../image/decrease.png')
                                    :
                                    step == 5 ?
                                        require('../../image/hassle-free.png')
                                        :
                                        step == 6 ?
                                            require('../../image/handshake.png')
                                            :
                                            require('../../image/a3.png')
                    } />
                <div className='step-txt' style={{ fontFamily: 'sans-serif', marginTop: 15, fontWeight: 'bold', fontSize: 20, color: themeTxt }}>
                    {stepTxt}
                </div>
                <div className='step-txt' style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt, lineHeight: 1.5 }}>
                    {stepDesc}
                </div>
            </div>
        )
    };

    const showBenefit1 = (step, stepTxt, stepDesc) => {
        return (
            <div className='step-parent' style={{ flex: 1, flexDirection: 'row', marginLeft: 10, marginRight: 0, marginTop: 15, marginBottom: 10, paddingRight: 20 }}>
                <img
                    style={{ width: 35, height: 35, marginTop: 3 }}
                    src={
                        step == 1 ?
                            require('../../image/browser2.png')
                            :
                            step == 2 ?
                                require('../../image/money2.png')
                                :
                                require('../../image/updated2.png')} />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
                    <div style={{ textAlign: 'left', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: 20, color: themeTxt }}>
                        {stepTxt}
                    </div>
                    <div style={{ textAlign: 'left', fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt, lineHeight: 1.5 }}>
                        {stepDesc}
                    </div>
                </div>
            </div>
        )
    };

    const openChat = (type) => {
        if (url === '')
            window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store to a Mobile App.&phone=+923074052841", '_blank')
        else
            window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store " + url + " to a " + type + " App.&phone=+923074052841", '_blank')
        // window.location.href = "whatsapp://send?text=I want to convert my website " + url + " to a " + type + " App.&phone=+923074052841"
    };

    const requestDemo = () => {
        navigate(
            '/shopify-step1',
            {
                state: {
                    url: url,
                    platform: 1,
                }
            }
        );
    };

    const showStatsItem = (icon, val, txt) => {
        return (
            <div className={`col-md-3 col-sm-6`} style={{ minWidth: 180, marginTop: 10, flex: 1 }}>
                <div className="counter_item text-center wow fadeInUp" data-wow-delay="0.1s">
                    <i className={`icon-${icon}`} style={{ fontSize: 40, marginBottom: 15, }}></i>
                    <h3 className="t_color" style={{ fontFamily: 'sans-serif', }} data-countup>{val}</h3>
                    <p style={{ marginTop: -5, fontFamily: 'sans-serif', }}>{txt}</p>
                </div>
            </div>
        )
    };

    return (
        <section className="" id="home" style={{}}>

            <div id='free-demo' className='free-demo-bg' style={{ width: '100%', marginTop: 60, display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center' }}>
                <div className="row">
                    <div className="intro-white" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>

                        <div className="" style={{ display: 'flex', flex: 2, flexDirection: 'column', alignItems: 'flex-start', paddingLeft: 30, paddingRight: 30, border: '0px solid #f2f2f2' }}>
                            <Reveal effect="fadeInUp">
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <h3 className="web-to-app-title1" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', color: '#fff', marginRight: 15 }}>
                                        Convert Your <span style={{ fontWeight: 'bold' }}>Shopify Store</span> to a Premium Mobile App
                                    </h3>
                                </div>
                                <div className="bubble b_three"></div>
                            </Reveal>
                            <Reveal effect="fadeInUp" duration={1500}>
                                <p className="mb_0 txt-shadow2" style={{ fontFamily: 'sans-serif', fontSize: 16, marginTop: -10, color: '#fff', marginRight: 20 }}>
                                    {/* Enter your Shopify Store URL to see what your APP would look like on Android & iOS for FREE! */}
                                    {/* Simply Enter Your Shopify Store URL to Preview Your FREE Android & iOS App Now! */}
                                    Boost CVR, LTV and more by turning your store into a native mobile app that automatically sync with everything on your store.
                                    Get the demo today.
                                    {/* Enter your shopify store URL to see what your APP would look like on Android & iOS for free. */}
                                </p>
                            </Reveal>

                            <FormGroup className='web-to-app-input-row' style={{ marginTop: -10, display: 'flex', flexWrap: 'wrap' }}>
                                <div className='input-margin-right' style={{ flex: 3, display: 'flex', alignItems: 'center', marginTop: 10, color: 'white' }}>
                                    <input
                                        style={{ flex: 1, height: 55, border: '1px solid #fff', paddingLeft: 20, paddingRight: 20, borderRadius: 5, minWidth: 300, backgroundColor: 'transparent', color: 'white' }}
                                        type='text'
                                        placeholder='Enter your store link...'
                                        value={url}
                                        onChange={(event) => {
                                            setUrl(event.target.value)
                                        }} />
                                    <style>
                                        {` 
                                            ::placeholder { 
                                                color: lightgray; 
                                            }`
                                        }
                                    </style>
                                    {url.length < 1 ?
                                        <button
                                            onClick={async () => {
                                                console.log('click');
                                                const text = await navigator.clipboard.readText();
                                                setUrl(text)
                                            }}
                                            style={{ fontFamily: 'sans-serif', color: '#fff', border: '0px', backgroundColor: 'transparent', marginLeft: -110, width: 110, textDecoration: 'underline' }}>
                                            Paste Link
                                        </button>
                                        :
                                        <button
                                            onClick={async () => {
                                                setUrl('')
                                            }}
                                            style={{ fontFamily: 'sans-serif', color: '#fff', border: '0px', backgroundColor: 'transparent', marginLeft: -70, width: 60, textDecoration: 'underline' }}>
                                            Clear
                                        </button>
                                    }
                                </div>
                                <div style={{ display: 'flex', minWidth: 200, flex: 1 }}>
                                    <a
                                        onClick={requestDemo}
                                        className='btn my-button my-btn-bg' style={{ fontFamily: 'sans-serif', backgroundColor: '#04B486', border: '0px solid yellow', color: '#fff', marginTop: 10, width: '100%', padding: 12, fontSize: 20 }}>
                                        Convert Now
                                    </a>
                                </div>
                                <div style={{ display: 'flex', marginTop: 25, flexWrap: 'wrap' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ minWidth: 330, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    App auto updates with store changes
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    Requires no effort to maintain
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    White glove service from an expert team
                                                </div>
                                            </div>
                                            {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    30-Day money back guarantee
                                                </div>
                                            </div> */}
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    Lifetime support
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ minWidth: 330, alignSelf: 'center', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                                            {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    No Apple $99 Fee, No Google $25 Fee
                                                </div>
                                            </div> */}

                                            {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    No Payment Until You Love The App
                                                </div>
                                            </div> */}
                                            {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    Unlimited Customizations
                                                </div>
                                            </div> */}
                                            {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    No Recurring Costs
                                                </div>
                                            </div> */}
                                            {/* <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <img
                                                    style={{ width: 25, height: 25, alignSelf: 'center' }}
                                                    src={require('../../image/tick.png')} />
                                                <div style={{ fontSize: 16, fontFamily: 'sans-serif', marginTop: 1, fontWeight: 'normal', marginLeft: 8, color: '#fff' }}>
                                                    Lifetime Support
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </FormGroup>
                        </div>
                        <div style={{ display: 'flex', flex: 1.2, minWidth: 300, alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img
                                    className='header-img'
                                    style={{ alignSelf: 'center' }}
                                    src={require('../../image/screenshots/demo.webp')} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <div style={{ display: 'flex', width: 50, height: 50, alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', borderRadius: 50 }}>
                                    <img style={{ width: 30, height: 30, objectFit: 'contain' }} src={require('../../image/apple-logo.png')} />
                                </div>
                                <div style={{ marginTop: 10, display: 'flex', width: 50, height: 50, alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', borderRadius: 50 }}>
                                    <img style={{ width: 30, height: 30 }} src={require('../../image/android-logo.png')} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div id='demo' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 10, paddingBottom: 0 }}>
                <div className="intro-white">
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20 }}>
                        <Reveal effect="fadeInUp">
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='web-to-app-title' style={{ marginBottom: 10, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                                    The Problem <span style={{ color: 'black' }}> </span> <span style={{ color: themeTxt }}>& Solution</span>
                                </div>
                                <img
                                    className='problem-img'
                                    style={{ marginTop: 20, marginBottom: 15, objectFit: 'contain' }}
                                    src={require('../../image/problem.png')} />
                                <div style={{ fontFamily: 'sans-serif', fontSize: 20, marginBottom: 30, textAlign: 'center', color: lightTxt }}>
                                    Your store is restricted to web only & hidden from millions of app store users. 3X your store visibilty & boost sales by launching on apple and google stores, the largest and most active app markets available.
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div> */}

            {/* <div id='demo' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div style={{ marginTop: 25, paddingBottom: 50 }}>
                    <Reveal effect="fadeInUp">
                        <h4 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: '#113E49', marginLeft: 20, marginRight: 20 }}>
                            👇 Watch Explainer Video
                        </h4>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <video
                                onPlay={() => {
                                    Api.addClick('PlayVideo')
                                }}
                                loop controls style={{ borderRadius: 10, border: '1px solid #04B486', maxWidth: window.innerWidth - 20, maxHeight: 500, alignSelf: 'center' }}>
                                <source src={videos} type="video/mp4" />
                            </video>
                        </div>

                    </Reveal>
                </div>
            </div> */}

            {/* <div id='benefits' style={{ width: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ marginBottom: 10, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                            Get Ready to Reap Incredible Benefits 🤩
                        </h3>
                        <div style={{ fontFamily: 'sans-serif', fontSize: 20, marginBottom: 30, textAlign: 'center', color: lightTxt }}>
                            Be just a click away from your customers and attract more conversions. Create a mobile app
                            that inspires sales and amplifies engagement.
                        </div>
                        <div
                            style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                            {showBenefit(1, 'Stand Out in The Crowd', "Become more trustworthy and innovative than your competitors. In a sea of millions of stores without a mobile app, having one will make your store stand out.")}
                            {showBenefit(3, 'Boost Customer Loyalty', 'Wow your customers with a sleek, fast & seamless store app experience! Will keep them glued to your brand, leaving your competitors in the dust.')}
                            {showBenefit(2, 'Ramp Up Sales', 'Attract 1000s of New Customers from Google and Apple Stores. Plus, Power Up Your Sales with Irresistible Push Notifications. 🔥')}
                            {showBenefit(4, 'Reduce Ad Costs', 'Retarget your customers using Push Notifications without spending a penny on Facebook/Google ads, hence decreasing your advertising costs.')}
                            {showBenefit(5, 'Hassle Free', "Everything inside your website works in the mobile app as well (because of Webview). Hence, there's no need to worry about store changes or plugins.")}
                            {showBenefit(6, 'Lifetime Support', 'We will stay connected with you and continue helping whenever you need us. We will make sure your app keeps working smoothly.')}
                        </div>
                    </Reveal>
                </div>
            </div> */}

            <div id='benefits1' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ marginBottom: 10, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                            {/* Major Benefits You Will Enjoy 🤩 */}
                            Why Store2app and not other app builders?
                        </h3>
                        <div style={{ fontFamily: 'sans-serif', fontSize: 20, marginBottom: 30, textAlign: 'center', color: lightTxt }}>
                            We simply turn your store into a mobile app that updates automatically, without rebuilding.
                        </div>
                        <div
                            className='why-store2app'
                            style={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                style={{ flex: 1, minWidth: 350, flexDirection: 'column', alignSelf: 'center', display: 'flex', marginTop: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                                {showBenefit1(1, 'Everything on your store just works', "Your custom design, integrations, third party tools, and anything else you have on your website will work automatically in your mobile app with no compatibility issues.")}
                                {showBenefit1(3, 'Update your app instantly', 'Any changes you make to your site immediately update in your app reducing overhead and duplicated work.')}
                                {showBenefit1(2, 'Save $100k+ in ongoing costs', "You already built a great store experience. Don't waste your time, money or energy rebuilding the same experience from scratch.")}
                            </div>
                            <div
                                style={{ flex: 1 }}>
                                <img
                                    src={require('../../image/store2app6.png')}
                                    style={{ minWidth: 350, width: '100%', alignSelf: 'center' }} />
                            </div>
                        </div>

                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#04B486', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-img'>
                                <img
                                    style={{ width: '100%', backgroundColor: '#04B486', objectFit: 'contain' }}
                                    src={require('../../image/screenshots/h1.png')} />
                            </div>
                            <div className='home-item-txt-right'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, marginBottom: 30, fontWeight: 'bold', color: '#fff', fontFamily: 'sans-serif' }}>
                                    Create a rich and modern shopping experience
                                </div>
                                <div style={{ fontFamily: 'sans-serif', fontSize: 18, color: '#fff', marginTop: 0 }}>
                                    Encourage engagement, reduce friction, and build a native mobile app for your most high-value buyers.
                                </div>
                                <div style={{ marginTop: 25, paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10, backgroundColor: 'white', borderRadius: 10 }}>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={bag} style={{ width: 27, marginTop: 5, height: 27, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Shoppers view 4.2X more products per session within shopping apps compared to the web.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                        <img src={upArrow} style={{ marginTop: 3, width: 23, height: 23, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Shopping apps convert 3X higher than mobile sites and 1.5X more than desktop.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap-reverse', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-txt-left'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, fontWeight: 'bold', color: themeTxt, fontFamily: 'sans-serif' }}>
                                    Get exclusive home-screen real estate
                                </div>
                                <div style={{ fontFamily: 'sans-serif', fontSize: 18, color: lightTxt, marginTop: 0 }}>
                                    Become the centre of your customer's online shopping experience by earning a spot on their home screen. It's free real estate.
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={repeat} style={{ width: 27, marginTop: 5, height: 27, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Increase the likelihood of repeat purchases through habitual checking.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                        <img src={boost} style={{ marginTop: 3, width: 23, height: 23, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Boost your LTV, as your app renders your brand unforgettable.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='home-item-img' style={{ backgroundColor: '#fafafa' }}>
                                <img
                                    style={{ width: '100%' }}
                                    src={require('../../image/screenshots/home.jpg')} />
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#04B486', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-img'>
                                <img
                                    style={{ width: '100%', backgroundColor: '#04B486' }}
                                    src={require('../../image/screenshots/1.png')} />
                            </div>
                            <div className='home-item-txt-right'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, marginBottom: 20, fontWeight: 'bold', color: '#fff', fontFamily: 'sans-serif' }}>
                                    Branded loading screen
                                </div>
                                <div style={{ fontFamily: 'sans-serif', fontSize: 18, color: '#fff', marginTop: 0 }}>
                                    Your app will open with a branded splash screen featuring your store logo and a loader. After a brief 2-second display, it seamlessly transitions to the home page.
                                </div>
                                <div style={{ marginTop: 25, paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10, backgroundColor: 'white', borderRadius: 10 }}>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={require('../../image/recall.png')} style={{ width: 27, marginTop: 5, height: 27, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Frequent visibility of the logo helps users remember the brand, aiding in future recall and trust building.
                                        </div>
                                    </div>
                                    {/* <div style={{ display: 'flex', marginTop: 15 }}>
                                        <img src={require('../../image/shield.png')} style={{ marginTop: 3, width: 27, height: 27, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Seeing a familiar logo can reassure users that they are interacting with a trusted and recognized entity.
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#fafafa', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap-reverse', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-txt-left'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, fontWeight: 'bold', color: themeTxt, fontFamily: 'sans-serif' }}>
                                    Thumb-friendly bottom tabs
                                </div>
                                <div style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt }}>
                                    Customers will love butter-smooth touch to seamlessly transition between screens. The tabs give extra and easy navigation option to the customers. We can customize tabs as per your requirement.
                                </div>
                                <div style={{ marginTop: 25 }}>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={require('../../image/like.png')} style={{ width: 27, marginTop: 5, height: 27, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Quick tabs encourage user interaction, leading to higher engagement and conversions.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='home-item-img' style={{ backgroundColor: '#fafafa' }}>
                                <img
                                    style={{ width: '100%' }}
                                    src={require('../../image/screenshots/2_2.png')} />
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#04B486', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-img'>
                                <img
                                    style={{ width: '100%', backgroundColor: '#04B486' }}
                                    src={require('../../image/screenshots/3.png')} />
                            </div>
                            <div className='home-item-txt-right'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, fontWeight: 'bold', color: '#fff', fontFamily: 'sans-serif' }}>
                                    Customizable & eye catching offer screen
                                </div>
                                <div style={{ marginTop: 0, fontFamily: 'sans-serif', fontSize: 18, color: '#fff' }}>
                                    A central screen to showcase your latest offers in an eye catching way to grab customer attention, and boost sales.
                                </div>
                                <div style={{ marginTop: 25, paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10, backgroundColor: 'white', borderRadius: 10 }}>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={require('../../image/offer.png')} style={{ width: 35, marginTop: 0, height: 35, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 8, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            A spotlight on hot deals & offers grabs attention, making customers want to buy more.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#fafafa', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap-reverse', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-txt-left'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, fontWeight: 'bold', color: themeTxt, fontFamily: 'sans-serif' }}>
                                    Send unlimited push notifications
                                </div>
                                <div style={{ fontFamily: 'sans-serif', fontSize: 18, color: lightTxt, marginTop: 0 }}>
                                    Alert customers of your latest offers, new products, and to keep them up to date with the delivery process.
                                </div>
                                <div style={{ marginTop: 25 }}>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={alert} style={{ width: 27, marginTop: 5, height: 27, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Instantly grabs attention with timely updates, unlike emails and SMS which can be ignored.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={require('../../image/bullseye.png')} style={{ width: 27, marginTop: 5, height: 27, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Retarget your customers without spending a penny on Facebook/Google ads.
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 20, paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 15, backgroundColor: '#047857', borderRadius: 10 }}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ marginLeft: 10, textAlign: 'left', fontStyle: 'italic', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#fff' }}>
                                                "The power of push notifications is so strong. In a world where people open email less and less each day, being able to do push notifications is the reason you do an app."
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                        <div style={{ fontFamily: 'sans-serif', fontSize: 16, color: 'gray', marginTop: 0, fontStyle: 'italic' }}>
                                            - David Cost, Rainbow Apparel
                                        </div>
                                    </div>
                                    {/* <div style={{ display: 'flex', marginTop: 15 }}>
                                        <img src={boost} style={{ marginTop: 3, width: 23, height: 23, objectFit: 'contain' }} />
                                        <div style={{ marginLeft: 10, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Boost your LTV, as your app renders your brand unforgettable.
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className='home-item-img' style={{ backgroundColor: '#fafafa' }}>
                                <img
                                    style={{ width: '100%' }}
                                    src={require('../../image/screenshots/44.png')} />
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#04B486', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-img'>
                                <img
                                    style={{ width: '100%', backgroundColor: '#04B486' }}
                                    src={require('../../image/screenshots/66.png')} />
                            </div>
                            <div className='home-item-txt-right'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, fontWeight: 'bold', color: '#fff', fontFamily: 'sans-serif' }}>
                                    Beautiful offline & rating popups
                                </div>

                                <div style={{ marginTop: 25, paddingLeft: 17, paddingRight: 15, paddingTop: 10, paddingBottom: 10, backgroundColor: 'white', borderRadius: 10 }}>
                                    <div style={{ display: 'flex', marginTop: 5 }}>
                                        <img src={require('../../image/no-wifi_.png')} style={{ width: 25, height: 25, objectFit: 'contain', marginTop: 2 }} />
                                        <div style={{ marginLeft: 15, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Once offline, a humurous little popup emerges to bring a smile to the user's face.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                        <img src={require('../../image/star_.png')} style={{ width: 25, height: 25, objectFit: 'contain', marginTop: 3 }} />
                                        <div style={{ marginLeft: 15, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            A rating popup frequently appears, allowing customers to provide a 5-star rating and review for the app.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15 }}>
                                        <img src={require('../../image/share_.png')} style={{ width: 25, height: 25, objectFit: 'contain', marginTop: 3 }} />
                                        <div style={{ marginLeft: 15, textAlign: 'left', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857', fontWeight: 'normal' }}>
                                            Users can also share the app with their friends resulting in more customers :)
                                        </div>
                                    </div>
                                </div>

                                {/* <div style={{ fontFamily: 'sans-serif', fontSize: 18, color: 'white', marginTop: 5 }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <img
                                            style={{ width: 30, height: 30 }}
                                            src={require('../../image/no-wifi.png')} />
                                        <div style={{ marginLeft: 10 }}>
                                            Once offline, a humurous little popup emerges to bring a smile to the user's face.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15, alignItems: 'flex-start' }}>
                                        <img
                                            style={{ width: 25, height: 25 }}
                                            src={require('../../image/star.png')} />
                                        <div style={{ marginLeft: 14 }}>
                                            A rating popup frequently appears, allowing customers to provide a 5-star rating and review. This contributes to the app ranking higher in stores to bring more traffic.
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 15, alignItems: 'flex-start' }}>
                                        <img
                                            style={{ width: 25, height: 25 }}
                                            src={require('../../image/share.png')} />
                                        <div style={{ marginLeft: 14 }}>
                                            Users can also share the app with their friends resulting in more installs & sales :)
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div id='features' style={{ width: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 20 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <div style={{ display: 'flex', flexWrap: 'wrap-reverse', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='home-item-txt-left'>
                                <div className='web-to-app-title' style={{ lineHeight: 1.1, fontWeight: 'bold', color: themeTxt, fontFamily: 'sans-serif' }}>
                                    We develop, publish and maintain your apps
                                </div>
                                <div style={{ fontFamily: 'sans-serif', marginTop: 5, fontSize: 18, color: lightTxt }}>
                                    You'll work with dedicated project manager who takes care of everything related to your apps. You can focus on your business while we run your apps on autopilot.
                                </div>
                            </div>
                            <div className='home-item-img' style={{ backgroundColor: 'transparent' }}>
                                <img
                                    style={{ width: '100%', borderRadius: 20 }}
                                    src={require('../../image/chat1.jpg')} />
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 0 }}>
                <div>
                    <Reveal effect="fadeInUp">
                        <Plans />
                    </Reveal>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 40, paddingBottom: 40, paddingLeft: 30, paddingRight: 20 }}>
                <div>
                    <Reveal effect="fadeInUp">
                        <div
                            style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexDirection: 'column', marginTop: 0, alignItems: 'flex-start', justifyContent: 'center' }}>
                            <img
                                style={{ alignSelf: 'center', width: 170, height: 170 }}
                                src={require('../../image/money-back.png')} />
                            <div className="web-to-app-title" style={{ marginTop: 20, alignSelf: 'center', marginLeft: 30, textAlign: 'center', marginRight: 30, color: themeTxt, fontFamily: 'sans-serif' }}>
                                30-Day Money Back Guarantee
                            </div>
                            <div style={{ marginTop: 8, textAlign: 'center', marginLeft: 30, alignSelf: 'center', marginRight: 30, fontSize: 20, fontWeight: 'bold', color: '#04B486', fontFamily: 'sans-serif' }}>
                                No Payment Until You Are Happy 😊
                            </div>
                            <div style={{ marginTop: 10, marginLeft: 20, marginRight: 20, alignSelf: 'center', fontSize: 18, color: lightTxt, fontFamily: 'sans-serif', textAlign: 'center' }}>
                                Your satisfaction is our priority! We have a 100% refund policy. After payment, if you encounter any issues and want your money back, we offer a full <b>100% refund</b>. We're committed to helping you grow without compromising your trust or hard-earned money!
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: 10, paddingTop: 0, paddingBottom: 30 }}>
                <div className="intro-white" style={{ backgroundColor: '#fff' }}>
                    <h3 className="web-to-app-title" style={{ marginBottom: 30, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                        Why Trust Us?
                        {/* Our Customers Are Happy */}
                    </h3>

                    <Testimonials />

                    <div className='buttons-margin-around' style={{ marginTop: 90, display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center', padding: 20, border: '1px solid #e6e6e6', borderRadius: 10, boxShadow: '0px 0px 10px #E6E6E6' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                className='profile-img'
                                style={{ alignSelf: 'center', marginTop: 10 }}
                                src={require('../../image/rating.jpg')} />
                        </div>

                        <div style={{ marginTop: 10, fontFamily: 'sans-serif', fontSize: 20, marginBottom: 30, textAlign: 'center', color: lightTxt }}>
                            Saifal N. is a  <span style={{ fontWeight: 'bold' }}>top rated & preferred</span> app development company on <span style={{ fontWeight: 'bold' }}>Freelancer.com</span>.
                            <br /> We launched <span style={{ fontWeight: 'bold' }}>Store2app</span> to empower e-commerce brands to enter the largest mobile app markets out there.
                            {/* <br/>All of our reviews above are original and coming straight from our profile. */}
                        </div>

                        <div style={{ width: '100%', flex: 1, marginTop: 0, paddingBottom: 10, paddingTop: 20, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {showStatsItem('briefcase', '117', 'Total Projects')}
                                {showStatsItem('happy', '100+', 'Happy Clients')}
                            </div>
                            <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {showStatsItem('calendar', '8', 'Years of Experience')}
                                {showStatsItem('clock', '24/7', 'Support')}
                            </div>
                        </div>

                        <div style={{ display: 'flex', marginTop: 0, flexWrap: 'wrap', flex: 1 }}>
                            <button
                                className='input-margin-right'
                                style={{ marginTop: 10, fontFamily: 'sans-serif', border: '0px', flex: 1, minWidth: 320, fontSize: 20, backgroundColor: '#04B486', color: 'white', borderRadius: 5, height: 60 }}
                                onClick={() => {
                                    window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                    Api.addClick('ViewPortfolio')
                                }}>
                                View on Freelancer.com
                            </button>
                            <button
                                style={{ marginTop: 10, fontFamily: 'sans-serif', flex: 1, border: '1.5px solid #04B486', minWidth: 320, fontSize: 20, backgroundColor: 'transparent', color: '#04B486', borderRadius: 5, height: 60 }}
                                onClick={() => {
                                    window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                    Api.addClick('ViewPortfolio')
                                }}>
                                Check 118 Client Reviews
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            {/* <div style={{ width: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: -30, paddingTop: 40, paddingBottom: 60 }}>
                <h3 className="web-to-app-title" style={{ marginBottom: 0, fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                    We are Trusted by Clients Worldwide
                </h3>

                <div style={{ width: '100%', flex: 1, paddingBottom: 10, paddingTop: 40, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', paddingLeft: 20, paddingRight: 20 }}>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {showStatsItem('briefcase', '117', 'Total Projects')}
                        {showStatsItem('happy', '100+', 'Happy Clients')}
                    </div>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {showStatsItem('calendar', '8', 'Years of Experience')}
                        {showStatsItem('clock', '24/7', 'Support')}
                    </div>
                </div>

                <div style={{ display: 'flex', marginTop: 20, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', paddingBottom: 15, borderRadius: 10 }}>
                    <img
                        style={{ alignSelf: 'center', borderRadius: 5, maxWidth: window.innerWidth - 20, maxHeight: 342 }}
                        src={require('../../image/freelancer.jpg')} />
                    <div style={{ display: 'flex', marginTop: 0, flexWrap: 'wrap', flex: 1, width: '100%', paddingLeft: 18, paddingRight: 18 }}>
                        <button
                            style={{ fontFamily: 'sans-serif', border: '0px', flex: 1, minWidth: 320, fontSize: 20, backgroundColor: '#04B486', color: 'white', borderRadius: 5, height: 60 }}
                            onClick={() => {
                                window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                Api.addClick('ViewPortfolio')
                            }}>
                            View Our Portfolio
                        </button>
                        <button
                            style={{ fontFamily: 'sans-serif', flex: 1, marginLeft: 20, border: '1.5px solid #04B486', minWidth: 320, fontSize: 20, backgroundColor: 'transparent', color: '#04B486', borderRadius: 5, height: 60 }}
                            onClick={() => {
                                window.open('https://www.freelancer.com/u/saif0347', '_blank');
                                Api.addClick('ViewPortfolio')
                            }}>
                            Client Reviews
                        </button>
                    </div>
                </div>

            </div> */}

            <div style={{ width: '100%', backgroundColor: '#000', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ marginBottom: 20, fontFamily: 'sans-serif', textAlign: 'center', color: '#fff' }}>
                            Your App Just 3 Steps Away 🤩
                        </h3>
                        <h3 style={{ marginBottom: 40, fontSize: 18, fontFamily: 'sans-serif', textAlign: 'center', color: '#fff' }}>
                            Achieve maximum potential with minimum effort. Convert your store to Android & iOS app in three simple steps.
                        </h3>
                        <div
                            style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexWrap: "wrap", marginTop: 10, alignItems: 'flex-start', justifyContent: 'center' }}>
                            {showStep(1, 'Enter Store Url', 'Give your shopify store link to convert into a mobile app.')}
                            {showStep(2, 'Test Your App', 'Get your App Demo within 24 hours and unlimited customizations.')}
                            {showStep(3, 'Live on Stores', 'We will go ahead and publish the app on App Stores.')}
                        </div>
                    </Reveal>
                </div>
            </div>

            <div style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 10, paddingBottom: 10 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                            Let's Convert Your Store to App Now!
                        </h3>
                        <div className="bubble b_three"></div>
                    </Reveal>
                    <Reveal effect="fadeInUp" duration={1500}>
                        <p className="mb_0" style={{ fontFamily: 'sans-serif', fontSize: 18, marginTop: -5, textAlign: 'center', color: '#545454' }}>
                            Please enter your store url to convert into mobile app.
                        </p>
                    </Reveal>

                    <FormGroup className='web-to-app-input-row1' style={{ marginTop: -10, display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                        <div style={{ flex: 3, display: 'flex', alignItems: 'center', marginTop: 10, marginRight: 10 }}>
                            <Input
                                style={{ height: 60, minWidth: 300 }}
                                type='text'
                                placeholder='https://'
                                value={url}
                                onChange={(event) => {
                                    setUrl(event.target.value)
                                }} />
                        </div>
                        <div style={{ display: 'flex', minWidth: 280, flex: 1 }}>
                            <a
                                onClick={requestDemo}
                                className='btn my-button' style={{ fontFamily: 'sans-serif', backgroundColor: '#04B486', color: 'white', marginTop: 10, width: '100%', padding: 14, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                                Get Demo Now
                            </a>
                        </div>

                    </FormGroup>

                </div>
            </div>

            <div id='showcase' style={{ width: '100%', backgroundColor: '#f4f4f9', display: 'flex', flexDirection: 'column', alignItems: 'stretch', paddingTop: 0 }}>
                <div className="intro-white">
                    <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                        Examples of Our Work
                    </h3>
                    <PortfolioSlider />
                </div>
            </div>

            <div id='FAQ' style={{ width: '100%', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: -30, paddingTop: 0, paddingBottom: 40 }}>
                <div className="intro-white">
                    <Reveal effect="fadeInUp">
                        <h3 className="web-to-app-title" style={{ fontFamily: 'sans-serif', textAlign: 'center', color: themeTxt }}>
                            Freqently Asked Questions
                        </h3>
                    </Reveal>
                    <FAQ />
                    <button
                        onClick={() => {
                            console.log('click');
                            openChat('Demo')
                        }}
                        style={{ fontFamily: 'sans-serif', alignSelf: 'center', width: '100%', marginTop: 35, textAlign: 'center', fontSize: 22, border: '0px', backgroundColor: 'transparent', fontWeight: 'bold', color: '#04B486', borderRadius: 5 }}>
                        Any other questions? Let's Discuss in Whatsapp
                    </button>
                </div>
            </div>

            <div style={{ display: 'flex', width: '100%', padding: 20, position: 'fixed', zIndex: 0, bottom: 5, alignItems: 'center', justifyContent: 'flex-end' }}>
                <button
                    onClick={() => {
                        openChat('Demo')
                    }}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid white', backgroundColor: themeTxt, width: 62, height: 62, borderRadius: 32, marginLeft: 15 }}>
                    <img
                        style={{ width: 35, height: 35 }}
                        src={require('../../image/wa1.png')} />
                </button>
            </div>

            <ToastContainer />
        </section>
    )
}

export default Body;