import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Link } from 'react-scroll';
import showToast from '../toast';
import FooterTwo from '../Footer/Footer';
import Navbar1 from './../Navbar/Navbar1';
import socialData from '../socialData';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Actions } from '../../redux/Actions'
import Api from '../Api/api'
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Reveal } from 'react-reveal/';
import appDemo from '../../assets/video/app-demo.mp4'

const themeTxt = '#113e49'
const lightTxt = '#6e6e6e'

const stripeLinks = {
    1: 'https://buy.stripe.com/bIY5kA2ITcdcdDa144',
    2: 'https://buy.stripe.com/eVa8wM0AL0uugPmbIJ',
    3: 'https://buy.stripe.com/14k7sI4R17WWeHe3ce',
}

const Step1 = (props) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch()
    let shopifyData = useSelector(state => state.MyReducer.shopifyData);

    const [url, setUrl] = useState(state.url ? state.url : '');
    // const [appName, setAppName] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [country, setCountry] = useState('');
    const [platform, setPlatfrom] = useState(state.platform ? state.platform : 1);
    const [showVideo, setShowVideo] = useState(false); // State for showing video

    const handleVideoOpen = () => {
        setShowVideo(true)
    }

    const handleVideoClose = () => {
        setShowVideo(false)
    }

    useEffect(() => {
        if (shopifyData != null) {
            if (shopifyData.step1) {
                setUrl(shopifyData.step1.url)
                setEmail(shopifyData.step1.email)
                setWhatsapp(shopifyData.step1.whatsapp)
                setPlatfrom(shopifyData.step1.platform)
            }
        }
        window.scrollTo(0, 0)
        Api.addClick('OpenGetDemo')
    }, [])


    const placeOrder = (fullApp) => {

        let shopifyDataTemp = {}
        shopifyDataTemp.url = url
        shopifyDataTemp.name = name
        shopifyDataTemp.email = email
        // shopifyDataTemp.whatsapp = whatsapp
        // shopifyDataTemp.country = country
        shopifyDataTemp.platform = getPlatformName()
        shopifyDataTemp.fullApp = fullApp
        shopifyDataTemp.paymentMethod = ''
        shopifyDataTemp.paymentResponse = ''
        shopifyDataTemp.added_date = moment() + ''
        shopifyDataTemp.timestamp = new Date()
        shopifyDataTemp.code = ''
        shopifyDataTemp.commission = ''
        shopifyDataTemp.status = 'pending'

        Api.placeShopifyOrder(shopifyDataTemp,
            (orderID) => {
                console.log('order placed: ' + orderID)
                localStorage.setItem('shopifyData', JSON.stringify(shopifyDataTemp))
                localStorage.setItem('orderID', orderID)
                window.location.href = stripeLinks[platform]
            },
            (error) => {
                // error
            })
    }

    const getPlatformName = () => {
        if (platform == 1)
            return 'Android'
        else if (platform == 2)
            return 'iOS'
        else if (platform == 3)
            return 'Android & iOS'
    }

    const verifyUrl = (url) => {
        const regex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
        return regex.test(url);
    }

    return (

        <div className="body_wrapper" style={{ display: 'flex', flexDirection: 'column' }}>

            <Navbar1 mClass="menu_two" mainlogo="logo-11.png" stickylogo="logo-00.png" />

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 110, alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
                <img
                    style={{ width: 360 }}
                    src={require('../../image/store2app6.png')} />
                <div style={{ fontSize: 26, fontFamily: 'sans-serif', marginTop: 10, color: '#113E49' }}>
                    {/* See Your Mobile App in Action */}
                    Convert Your Store to Mobile App
                    {/* See Store2app in Action */}
                </div>
            </div>

            <div className='shopify-step-parent' style={{ display: 'flex', marginBottom: 100, alignSelf: 'center', flexDirection: 'column', alignItems: 'stretch' }}>

                <div style={{ marginTop: 0, marginLeft: 0, marginRight: 0, alignSelf: 'center', fontSize: 16, color: lightTxt, fontFamily: 'sans-serif', textAlign: 'center' }}>
                    Please fill the details and see your mobile app in action.
                    {/* Book a 30-minute consultation to walk through your app requirements and see a preview of your own app built by our team. */}
                </div>

                <div
                    className='col'
                    style={{ flex: 1, paddingTop: 0, marginTop: 20, display: 'flex', flexDirection: 'column', overflow: 'scroll' }}>


                    <TextField
                        id="outlined-basic"
                        label="Store Url"
                        sx={{ fontSize: 12, marginTop: 1 }}
                        variant="outlined"
                        value={url}
                        size='medium'
                        onChange={(event) => {
                            setUrl(event.target.value)
                        }} />

                    <div style={{ marginTop: 10, fontSize: 16, fontFamily: 'sans-serif', }}>
                        Choose Platform
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
                        <button
                            onClick={() => {
                                setPlatfrom(1)
                            }}
                            style={{ display: 'flex', flex: 1, color: 'gray', backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 10, paddingTop: 15, paddingBottom: 15, border: platform == 1 ? '2px solid #0db3c7' : '1px solid lightgray', borderRadius: 10 }}>
                            <img
                                style={{ width: 40, height: 40 }}
                                src={require('../../image/android.png')} />
                            <div style={{ fontFamily: 'sans-serif', fontSize: 16, fontWeight: 'bold', marginTop: 5 }}>
                                Android
                            </div>
                        </button>
                        <button
                            onClick={() => {
                                setPlatfrom(2)
                            }}
                            style={{ display: 'flex', flex: 1, color: 'gray', backgroundColor: 'white', marginLeft: 10, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 10, paddingTop: 15, paddingBottom: 15, border: platform == 2 ? '2px solid #0db3c7' : '1px solid lightgray', borderRadius: 10 }}>
                            <img
                                style={{ width: 40, height: 40 }}
                                src={require('../../image/apple.png')} />
                            <div style={{ fontFamily: 'sans-serif', fontSize: 16, fontWeight: 'bold', marginTop: 5 }}>
                                iOS
                            </div>
                        </button>
                        <button
                            onClick={() => {
                                setPlatfrom(3)
                            }}
                            style={{ display: 'flex', flex: 1, color: 'gray', backgroundColor: 'white', marginLeft: 10, alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: 10, paddingTop: 15, paddingBottom: 15, border: platform == 3 ? '2px solid #0db3c7' : '1px solid lightgray', borderRadius: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <img
                                    style={{ width: 40, height: 40, marginTop: 2 }}
                                    src={require('../../image/android.png')} />
                                <img
                                    style={{ width: 40, height: 40, marginLeft: 0 }}
                                    src={require('../../image/apple.png')} />
                            </div>
                            <div style={{ fontFamily: 'sans-serif', fontSize: 16, textAlign: 'center', fontWeight: 'bold', marginTop: 5 }}>
                                Both
                            </div>
                        </button>
                    </div>

                    <TextField
                        id="outlined-basic"
                        label="Your Name"
                        style={{ marginTop: 20 }}
                        variant="outlined"
                        value={name}
                        size='medium'
                        onChange={(event) => {
                            setName(event.target.value)
                        }} />

                    <TextField
                        id="outlined-basic"
                        label="Your Email"
                        style={{ marginTop: 15 }}
                        variant="outlined"
                        value={email}
                        size='medium'
                        onChange={(event) => {
                            setEmail(event.target.value)
                        }} />

                    {/* <div style={{ width: '100%', marginTop: 5, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                        <div style={{ color: 'gray' }}>
                            Enter Whatsapp (Select country also)
                        </div>
                        <PhoneInput
                            containerStyle={{ with: '100%', height: 50 }}
                            inputStyle={{ width: '100%', height: 50, fontSize: 16 }}
                            country={'us'}
                            enableSearch
                            placeholder="Enter Whatsapp #"
                            value={whatsapp}
                            onChange={(value, country, e, formattedValue) => {
                                console.log('value: ' + value);
                                console.log('country: ' + country.name);
                                console.log('formattedValue: ' + formattedValue);
                                setWhatsapp(formattedValue)
                                setCountry(country.name)
                            }} />
                    </div> */}

                    {/* <TextField
                        id="outlined-basic"
                        label="Whatsapp (Optional)"
                        style={{ marginTop: 10 }}
                        type='phone'
                        variant="outlined"
                        value={whatsapp}
                        size='medium'
                        onChange={(event) => {
                            setWhatsapp(event.target.value)
                        }} /> */}

                    {/* <div style={{ display: 'flex', flexDirection: 'column', minWidth: 280, flex: 1 }}>
                        <Link
                            onClick={() => {
                                console.log('click');
                                if (url === '') {
                                    showToast('error', 'Please fill details!')
                                    return
                                }
                                else if (email === '') {
                                    showToast('error', 'Please fill details!')
                                    return
                                }
                                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                                    showToast('error', 'Please enter a valid email!')
                                    return
                                }
                                else if (whatsapp === '') {
                                    showToast('error', 'Please enter whatsapp no.')
                                    return
                                }
                                else if (whatsapp.length < 10) {
                                    showToast('error', 'Invalid whatsapp no.')
                                    return
                                }
                                else if (country === '') {
                                    showToast('error', 'Please select country.')
                                    return
                                }

                                let data = {
                                    url: url,
                                    name: name,
                                    email: email,
                                    whatsapp: whatsapp,
                                    country: country,
                                    platform: platform,
                                    fullApp: false
                                }
                                let shopifyDataTemp = { ...shopifyData }
                                shopifyDataTemp['step1'] = data
                                dispatch(Actions.setShopifyData(shopifyDataTemp))
                                // navigate('/shopify-step2', { replace: false });
                                placeOrder(false)
                            }}
                            className='btn my-button' style={{ fontFamily: 'sans-serif', height: 55, backgroundColor: 'black', marginTop: 25, color: 'white', width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                            Get Limited Demo App ($9)
                        </Link>
                    </div> */}

                    <div style={{ display: 'flex', marginTop: 20, flexDirection: 'column', minWidth: 280, flex: 1 }}>
                        <Link
                            onClick={() => {
                                console.log('click');
                                if (url === '') {
                                    showToast('error', 'Please fill details')
                                    return
                                }
                                else if (email === '') {
                                    showToast('error', 'Please fill details')
                                    return
                                }
                                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                                    showToast('error', 'Please enter a valid email')
                                    return
                                }
                                else if (!verifyUrl(url)) {
                                    showToast('error', 'Please enter a valid url')
                                    return
                                }

                                // else if (whatsapp === '') {
                                //     showToast('error', 'Please enter whatsapp no.')
                                //     return
                                // }
                                // else if (whatsapp.length < 10) {
                                //     showToast('error', 'Invalid whatsapp no.')
                                //     return
                                // }
                                // else if (country === '') {
                                //     showToast('error', 'Please select country.')
                                //     return
                                // }

                                let data = {
                                    url: url,
                                    name: name,
                                    email: email,
                                    platform: platform,
                                    fullApp: true
                                }
                                let shopifyDataTemp = { ...shopifyData }
                                shopifyDataTemp['step1'] = data
                                dispatch(Actions.setShopifyData(shopifyDataTemp))
                                // navigate('/shopify-step2', { replace: false });
                                placeOrder(true)
                            }}
                            className='btn my-button' style={{ fontFamily: 'sans-serif', height: 55, backgroundColor: '#04B486', marginTop: 10, color: 'white', width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', border: 'none' }}>
                            Order App Now (${platform == 1 ? '49' : platform == 2 ? '69' : '99'})
                        </Link>
                    </div>

                    <div style={{ display: 'flex', marginTop: 0, flexDirection: 'column', minWidth: 280, flex: 1 }}>
                        <Link
                            onClick={() => {
                                console.log('click');
                                handleVideoOpen()
                            }}
                            className='btn my-button' style={{ fontFamily: 'sans-serif', height: 55, border: '1px solid #04B486', marginTop: 10, color: '#04B486', width: '100%', padding: 12, fontSize: 20, fontStyle: 'bold', backgroundColor: 'transparent' }}>
                            View Sample App Demo
                        </Link>
                    </div>

                    <div style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                        <img
                            style={{ width: 35, height: 35, borderRadius: 50, border: '1px solid lightgray' }}
                            src={require('../../image/ahmed.png')} />
                        <img
                            style={{ marginLeft: -5, width: 35, height: 35, borderRadius: 50, border: '1px solid lightgray' }}
                            src={require('../../image/raymond.png')} />
                        <img
                            style={{ marginLeft: -5, width: 35, height: 35, borderRadius: 50, border: '1px solid lightgray' }}
                            src={require('../../image/chris.png')} />
                        <img
                            style={{ marginLeft: -5, width: 35, height: 35, borderRadius: 50, border: '1px solid lightgray' }}
                            src={require('../../image/pslocks.png')} />
                        <div style={{ marginLeft: 10, alignSelf: 'center', fontSize: 16, color: lightTxt, fontFamily: 'sans-serif' }}>
                            Join 117+ others
                        </div>
                    </div>

                </div>

                <div style={{ marginTop: 20, textAlign: 'center', marginLeft: 30, marginRight: 30, alignSelf: 'center', fontSize: 16, color: themeTxt, fontFamily: 'sans-serif' }}>
                    You will get app demo within 24 hours. And we will provide you <b>unlimited revisions</b> until you are super satisfied :)
                </div>

                <div style={{ width: '100%', backgroundColor: '#fff', marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <div>
                        <Reveal effect="fadeInUp">
                            <div
                                style={{ flexDirection: 'row', alignSelf: 'center', display: 'flex', flexDirection: 'column', marginTop: 0, alignItems: 'flex-start', justifyContent: 'center' }}>
                                <img
                                    style={{ alignSelf: 'center', width: 170, height: 170 }}
                                    src={require('../../image/money-back.png')} />
                                <div className="web-to-app-title" style={{ marginTop: 10, fontWeight: 'bold', alignSelf: 'center', marginLeft: 30, textAlign: 'center', marginRight: 30, color: themeTxt, fontFamily: 'sans-serif', fontSize: 22 }}>
                                    30-Day Money Back Guarantee
                                </div>
                            </div>
                        </Reveal>
                    </div>
                </div>

                <div style={{ marginTop: 20, paddingTop: 20, paddingBottom: 20, border: '1px solid #047857', borderRadius: 10 }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ marginLeft: 20, marginRight: 20, textAlign: 'left', fontStyle: 'italic', fontFamily: 'sans-serif', fontSize: 18, marginTop: 0, color: '#047857' }}>
                            "Something we’ve noticed is that users who use the mobile app are better customers. Either they spend more or they spend more often."
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginTop: 5 }}>
                    <div style={{ marginLeft: 20, fontFamily: 'sans-serif', fontSize: 16, color: 'gray', marginTop: 0, fontStyle: 'italic' }}>
                        Jack & Jones
                    </div>
                </div>

                <button
                    onClick={() => {
                        console.log('click');
                        if (url === '')
                            window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store to a Mobile App.&phone=+923074052841", '_blank')
                        else
                            window.open("https://api.whatsapp.com/send?text=I want to convert my shopify store " + url + " to a " + getPlatformName(platform) + " App.&phone=+923074052841", '_blank')
                    }}
                    style={{ border: '0px solid green', padding: 15, fontFamily: 'sans-serif', alignSelf: 'center', width: '100%', marginTop: 35, textAlign: 'center', fontSize: 22, backgroundColor: 'transparent', fontWeight: 'bold', color: '#04B486', borderRadius: 5 }}>
                    Any questions? Let's Discuss in Whatsapp
                </button>

            </div>
            <FooterTwo noHeight socialData={socialData} />

            {showVideo && (
                <div style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    height: 500,
                    backgroundColor: 'white',
                    zIndex: 1000,
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor:'#1C1C1C',
                    borderRadius: 5,
                }}>
                    <button
                        onClick={() => setShowVideo(false)}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            backgroundColor: 'white',
                            width: 35,
                            height: 35,
                            border: 'none',
                            fontSize: 32,
                            cursor: 'pointer'
                        }}>
                        &times;
                    </button>
                    <video
                        height="480"
                        controls
                        autoPlay>
                        <source src={appDemo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}

            {showVideo && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 999
                }} onClick={() => setShowVideo(false)}></div>
            )}

        </div>
    )
}

export default Step1;